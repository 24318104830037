import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { environment } from '@src/environments/environment';
import { AppFeatureToggleService } from './app-feature-toggle.guard';
import { OverviewContainerComponent } from './containers/overview-container/overview-container.component';
import { Views } from './models/views.model';

const routes: Routes = [
  {
    path: 'overview',
    component: OverviewContainerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        // value below should be changed to 'device-view' once the view has been created
        redirectTo: 'tcu-events',
        pathMatch: 'full'
      },
      {
        path: 'device-view',
        loadComponent: () =>
          import(
            './containers/device-view-container/device-view-container.component'
          ).then((m) => m.DeviceViewContainerComponent),
        data: {
          label: 'deviceView',
          view: Views.DEVICE_VIEW,
          featureKey: environment.devCycle.deviceViewFeature
        },

        canActivate: [AuthGuard, AppFeatureToggleService]
      },
      {
        path: 'tcu-events',
        loadComponent: () =>
          import(
            './containers/tcu-events-container/tcu-events-container.component'
          ).then((m) => m.TcuEventsContainerComponent),
        data: {
          label: 'tcuEventsView',
          view: Views.TCU_EVENTS,
          featureKey: environment.devCycle.tcuEventFeature
        },

        canActivate: [AuthGuard, AppFeatureToggleService]
      },
      {
        path: 'tablet-events',
        loadComponent: () =>
          import(
            './containers/tablet-events-container/tablet-events-container.component'
          ).then((m) => m.TabletEventsContainerComponent),
        data: {
          label: 'tabletEventsView',
          view: Views.TABLET_DEVICE_EVENTS,
          featureKey: environment.devCycle.tabletEventFeature
        },

        canActivate: [AuthGuard, AppFeatureToggleService]
      },
      {
        path: 'rfid-events',
        loadComponent: () =>
          import(
            './containers/rfid-events-container/rfid-events-container.component'
          ).then((m) => m.RfidEventsContainerComponent),
        data: {
          label: 'rfidEventsView',
          view: Views.RFID_EVENTS,
          featureKey: environment.devCycle.rfidEventFeature
        },

        canActivate: [AuthGuard, AppFeatureToggleService]
      },
      {
        path: 'inspection-device-events',
        loadComponent: () =>
          import(
            './containers/inspection-device-events-container/inspection-device-events-container.component'
          ).then((m) => m.InspectionDeviceEventsContainerComponent),
        data: {
          label: 'inspectionDeviceView',
          view: Views.INSPECTION_DEVICE_EVENTS
        },
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'status',
    loadComponent: () =>
      import('./containers/status-container/status-container.component').then(
        (m) => m.StatusContainerComponent
      )
  },
  {
    path: '**',
    redirectTo: 'overview',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
