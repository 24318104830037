<div class="overview-container">
  <app-page-title>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h1>
        {{ translateService.get(translations?.overview.inspectionDeviceTitle) |
        async | translate }}
      </h1>
    </div>
  </app-page-title>

  <nav mat-tab-nav-bar [tabPanel]="tabPanel">
    <ng-container *ngFor="let link of navLinks">
      <a
        mat-tab-link
        #rla="routerLinkActive"
        [active]="activeLink === link"
        (click)="activeLink = link"
        *ngIf="link.featureKey$ | async"
        [routerLinkActiveOptions]="{ exact: false }"
        [routerLink]="link.path"
        queryParamsHandling="preserve"
        routerLinkActive>
        {{ translateService.get(translations.overview.matTabs[link.label]) |
        async | translate }}
      </a>
    </ng-container>
  </nav>
  <mat-tab-nav-panel #tabPanel>
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>
</div>
