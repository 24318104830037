import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { CoreModule, FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
  RouterModule,
  Routes
} from '@angular/router';
// child components
import { PageTitleComponent } from '@app/components/page-title/page-title.component';
import { Translations } from '@app/services/i18n/translations.service';
import {
  FeatureToggleService,
  FeatureTogglesModule
} from '@zonar-ui/feature-toggle';
// zonar-ui pattern library
import { TranslateModule, TranslateService } from '@zonar-ui/i18n';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { merge, of, Subject } from 'rxjs';
import { filter, map, startWith, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-overview-container',
  standalone: true,
  imports: [
    FlexLayoutModule,
    CoreModule,
    CommonModule,
    RouterModule,
    MatCardModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    NgxSkeletonLoaderModule,
    PageTitleComponent,
    TranslateModule,
    FeatureTogglesModule
  ],
  templateUrl: './overview-container.component.html',
  styleUrls: ['./overview-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewContainerComponent implements OnInit, OnDestroy {
  public cardData;
  public columns;
  public filterList;
  public pageTitle: string;
  public navLinks = [];
  public activeLink = null;

  private onDestroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private featureToggleService: FeatureToggleService,
    public translateService: TranslateService,
    public translations: Translations
  ) {}

  ngOnInit(): void {
    this.navLinks =
      !!this.route.routeConfig && !!this.route.routeConfig.children.length
        ? this.buildNavigatingItems(this.route.routeConfig.children)
        : [];
    this.activeLink = this.navLinks[0];
    this.subscribeForRouteChanges();
  }

  buildNavigatingItems(routes: Routes) {
    return routes
      .filter((route) => route.data)
      .map(({ path = '', data }) => ({
        path: path,
        label: data.label,
        url: `overview/${path}`,
        featureKey$: data.featureKey
          ? this.featureToggleService
              .valueForKey(data.featureKey)
              .pipe(map((v) => Boolean(v)))
          : of(true)
      }));
  }

  subscribeForRouteChanges() {
    this.router.events
      .pipe(
        takeUntil(this.onDestroy$),
        filter((event) => event instanceof NavigationStart),
        startWith(this.router),
        tap((event: NavigationStart) => {
          let currentTabRoute = event.url.split('/').at(-1);
          this.pageTitle = event.url.split('/').at(-2);
          this.activeLink = this.navLinks.find((link) =>
            currentTabRoute.startsWith(link.path)
          );
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
